import * as Yup from "yup";

require("./globalScheme");
const contactScheme = Yup.object().shape({
  first_name: Yup.string()
    .isCharterOnly()
    .required("Bitte geben Sie Ihren Vornamen ein."),
  last_name: Yup.string()
    .isCharterOnly()
    .required("Bitte geben Sie Ihren Nachnamen ein."),
  email: Yup.string()
    .email("Bitte geben Sie eine gültige Email ein.")
    .required("Bitte geben Sie eine Email an."),
  subject: Yup.string().required("Bitte geben Sie einen Betreff ein."),
  message: Yup.string().required("Bitte geben Sie Ihre Nachricht ein."),
  privacy: Yup.array()
    .min(1, "Bitte bestätigen Sie die Datenschutzerklärung")
    .required("Bitte bestätigen Sie die Datenschutzerklärung")
    .of(Yup.mixed().oneOf(["accepted"])),
});

export default contactScheme;
