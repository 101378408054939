import React from "react";
import { FormControl, FormLabel, FormHelperText, Box } from "@material-ui/core";
import { useField } from "formik";
import MyCheckbox from "./MyCheckbox";

const MyCheckboxGroup = ({
  label,
  options,
  optionalChildren,
  style,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <FormControl style={style} error={!!errorText}>
      <FormLabel component="legend">{label}</FormLabel>
      <Box display="flex">
        {options.map(({ label, value }, index) => (
          <MyCheckbox
            key={index}
            {...field}
            type="checkbox"
            value={value}
            label={label}
          />
        ))}
      </Box>
      <Box ml={4} mt={1}>
        {optionalChildren}
      </Box>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default MyCheckboxGroup;
