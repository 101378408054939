import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "../components/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Navbar from "../components/nav/Nav";
import Footer from "../components/nav/Footer";
import { Formik, Form } from "formik";
import { loadRecaptcha } from "../helper";
import MyTextField from "../components/fields/MyTextField";
import MyCheckboxGroup from "../components/fields/MyCheckboxGroup";
import { SITE_KEY } from "../helper";
import { isLoggedIn } from "../api";
import { BASE_URL } from "../api/config";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../redux/actions";
import Loading from "../components/Loading";
import Message from "../components/authenticated/Message";
import { lighten } from "@material-ui/core/styles";
import contactSchema from "../helper/schemes/contactScheme";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import { texts } from "../data/texts";
import responseMessages from "../helper/responseHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "2rem",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.primary.light,
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textDecoration: "none",
  },
  datenschutzTypo: {
    lineHeight: "1",
  },
  captchaLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: "bold",
  },
  recaptchaText: {
    fontSize: "0.6rem",
  },
}));

const Contact = ({ userInfo }) => {
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const loggedIn = isLoggedIn();
  const dispatch = useDispatch();
  const initialValues = {
    first_name: userInfo?.first_name || "",
    last_name: userInfo?.last_name || "",
    email: userInfo?.email || "",
    subject: "",
    message: "",
    type: "customer",
    site_ref: process.env.GATSBY_BRANDING,
    privacy: [],
  };
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  const submitData = (data, formikProps) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "thgcontact" })
        .then((token) => requestToServer(data, token, formikProps));
    });
  };

  const requestToServer = async (data, token, formikProps) => {
    setLoading(true);
    const resp = await fetch(`${BASE_URL}/thg/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "*",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ...data,
        token,
      }),
    });
    if (resp.status === 200) {
      const json = await resp.json();

      if (json?.data) {
        dispatch(
          actions.message.addMessage({
            type: "success",
            title: "Erfolg",
            description: "Ihre Kontaktanfrage wurde erfolgreich übermittelt.",
          })
        );
      }
      setLoading(false);
      formikProps.resetForm();
    } else {
      dispatch(
        actions.message.addMessage({
          type: "error",
          title: "Fehler",
          description: "Es ist ein Fehler aufgetreten.",
        })
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dirty) {
      loadRecaptcha();
    }
  }, [dirty]);

  if (loading) return <Loading />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contactSchema}
      onSubmit={(values, formikProps) => {
        // same shape as initial values

        submitData(values, formikProps);
      }}
    >
      {(props) => {
        if (!dirty && props.dirty) {
          setDirty(true);
        }
        return (
          <Form className={classes.root}>
            <Navbar />
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Message />
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Kontakt aufnehmen
                </Typography>
                <div className={classes.form} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        autoComplete="fname"
                        name="first_name"
                        variant="outlined"
                        fullWidth
                        id="first_name"
                        label="Vorname"
                        disabled={loggedIn}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        variant="outlined"
                        fullWidth
                        id="last_name"
                        label="Nachname"
                        name="last_name"
                        autoComplete="lname"
                        disabled={loggedIn}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MyTextField
                        variant="outlined"
                        fullWidth
                        id="email"
                        label="Email Adresse"
                        name="email"
                        autoComplete="email"
                        disabled={loggedIn}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MyTextField
                        variant="outlined"
                        fullWidth
                        id="subject"
                        label="Betreff"
                        name="subject"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MyTextField
                        variant="outlined"
                        fullWidth
                        id="message"
                        label="Was möchten Sie uns mitteilen?"
                        name="message"
                        autoComplete="comment"
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MyCheckboxGroup
                        label=""
                        options={[
                          {
                            label: (
                              <Box
                                style={{ textAlign: "left" }}
                                className={classes.datenschutz}
                              >
                                <Typography
                                  variant="overline"
                                  className={classes.datenschutzTypo}
                                >
                                  Hiermit bestätige ich, dass ich die{" "}
                                  <Link
                                    href="https://m3e-gmbh.com/datenschutz"
                                    target="_blank"
                                    external
                                    rel="noopener noreferrer"
                                    className={classes.link}
                                  >
                                    Datenschutzerklärung
                                  </Link>{" "}
                                  zur Kenntnis genommen habe
                                </Typography>
                              </Box>
                            ),
                            value: "accepted",
                          },
                        ]}
                        name="privacy"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    type="submit"
                  >
                    Kontakt aufnehmen
                  </Button>
                  <Box textAlign="center" mt={1}>
                    <Typography
                      className={classes.recaptchaText}
                      variant="caption"
                    >
                      This site is protected by reCAPTCHA and the Google{" "}
                      <Link
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.captchaLink}
                      >
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      <Link
                        href="https://policies.google.com/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.captchaLink}
                      >
                        Terms of Service
                      </Link>{" "}
                      apply.
                    </Typography>
                  </Box>
                </div>
              </div>

              <br />
            </Container>
            {/* <pre>{JSON.stringify(props)}</pre> */}
            <Footer />
          </Form>
        );
      }}
    </Formik>
  );
};

export default connect((state) => ({
  userInfo: state?.user?.info,
}))(Contact);
